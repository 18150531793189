import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Typography from '@mui/material/Typography';
import ImageList from '../components/ImageList';
import Seo from '../components/Seo';

const Drawings = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, JPG]
              )
            }
          }
        }
      }
    }
  `);
  const [node, setNode] = React.useState(null);
  const images = data.allFile.edges.sort((a, b) => a.node.base.slice(0, 2) - b.node.base.slice(0, 2));
  return (
    <>
      <Seo title="Drawings" />
      <Typography variant="h4" align="center" sx={{ fontWeight: 700, marginBottom: '32px' }}>Drawings</Typography>
      <div>
        <ImageList images={images.slice(5, 8)} setNode={setNode} selected={node} width={300} height={410} bigWidth={600} bigHeight={820} />
        <ImageList images={images.slice(8, 11)} setNode={setNode} selected={node} width={500} height={328} bigWidth={800} bigHeight={525} />
      </div>
    </>
  );
};

export default Drawings;
