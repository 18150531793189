import * as React from 'react';
import Card from '@mui/material/Card';
import { GatsbyImage } from 'gatsby-plugin-image';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'selected' || prop !== 'width' || prop !== 'height',
})(({ theme, width, height, selected, hasSelection, bigWidth }) => ({
  top: '0px',
  visibility: hasSelection && !selected ? 'hidden' : 'visible',
  opacity: hasSelection && !selected ? '0' : '1',
  width: !hasSelection ? width : (selected ? bigWidth : '0'),
  height: !hasSelection ? height : (selected ? '100%' : '0'),
  marginRight: theme.spacing(2),
  transition: theme.transitions.create(['top', 'width', 'height', 'opacity', 'visibility'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.complex,
  }),
  '&:hover': {
    cursor: 'pointer',
    position: 'relative',
    top: '-10px',
    transition: theme.transitions.create(['top', 'width', 'height', 'opacity', 'visibility'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
  },
}));

const ImageContainer = styled('div')(
  ({ theme, containsSelection, bigHeight }) => ({
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    height: containsSelection ? bigHeight : '',
  })
);

const ImageList = ({ images, width, bigWidth, bigHeight, height, selected, setNode }) => {
  return (
    <ImageContainer containsSelection={images.includes(selected)} bigHeight={bigHeight}>
        {images.map((image) => (
          <StyledCard 
            onClick={() => {
              if (selected !== null) {
                setNode(null)
              } else {
                setNode(image);
              }
            }} 
            height={height}
            width={width}
            bigWidth={bigWidth}
            hasSelection={!!selected}
            selected={selected === image}
          >
            <GatsbyImage alt="" loading="eager" image={image.node.childImageSharp.gatsbyImageData} />
          </StyledCard>
        ))}
    </ImageContainer>
  );
};

export default ImageList;